<template>
  <b-row class="justify-content-center bug-for-task">
    <b-col md="8">
      <b-card>
        <b-card-title class="mb-1 font-medium-5 font-weight-bolder text-center text-uppercase"
          >Creating a bug for a task
        </b-card-title>

        <p class="mb-25"><span class="font-weight-bold">Number:</span> {{ currentTask.id }}</p>
        <p class="mb-25"><span class="font-weight-bold">Subject:</span> {{ currentTask.subject }}</p>

        <app-collapse accordion class="mt-1" type="border">
          <app-collapse-item title="Task description">
            <p class="mb-25" v-html="currentTask.description" />
          </app-collapse-item>
        </app-collapse>

        <b-row class="mt-2">
          <b-col md="6">
            <b-form-group label="Тестирующий" label-for="basicInput">
              <b-form-select v-model="selectedTesting" :options="optionsTesting" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Исправляющий" label-for="basicInput">
              <b-form-select v-model="selectedFixed" :options="optionsFixed" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col md="6">
            <b-form-group label="Basic Input" label-for="basicInput">
              <b-form-input id="basicInput" placeholder="Enter Email" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Basic Input" label-for="basicInput">
              <b-form-input id="basicInput" placeholder="Enter Email" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Basic Input" label-for="basicInput">
              <b-form-input id="basicInput" placeholder="Enter Email" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-button variant="primary"> Create</b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {BButton, BCard, BCardTitle, BCol, BFormGroup, BFormInput, BFormSelect, BRow} from 'bootstrap-vue'
import TasksService from '@/services/tasks.service'
import AppCollapse from '@core/components/app-collapse/AppCollapse'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'

export default {
  components: {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BButton,
    AppCollapse,
    AppCollapseItem,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
  data() {
    return {
      selectedTesting: 'Елена',
      optionsTesting: ['Елена', 'Николай'],
      selectedFixed: 'Backend',
      optionsFixed: ['Backend', 'Frontend', 'UI/UX'],
    }
  },
  computed: {
    currentTask() {
      return this.$store.state.task.task
    },
  },
  mounted() {
    TasksService.getTask(this.$route.params.id)
  },
}
</script>

<style lang="scss" scoped>
.bug-for-task {
  p {
    margin-bottom: 0 !important;
  }
}
</style>
